import { updateAircraftClash } from './../../../../store/actions/aircraft-planning.action';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AircraftPlanning, UpdatePlanningActForm } from 'src/app/models/aircraft-planning';
import moment from 'moment';
import { updateAircraftPlanning } from 'src/app/store/actions/aircraft-planning.action';
import { map, take, tap } from 'rxjs/operators';
import { selectProfileUserRights$ } from 'src/app/store/selectors/profile.selectors';
import { MatTableDataSource } from '@angular/material/table';
import { AircraftPlanningEffects } from 'src/app/store/effects/aircraft-planning.effects';
import { Zone } from 'src/app/models/zone';
import { selectZones$, selectZonesLoaded$ } from 'src/app/store/selectors/zone.selector';
import { asyncRequireMatchValidator } from 'src/app/components/shared/require-match-validator.directive';

@Component({
  selector: 'app-dialog-edit-planning-act',
  templateUrl: './dialog-edit-aircraft-planning.component.html',
  styleUrls: ['./dialog-edit-aircraft-planning.component.scss']
})
export class DialogEditAircraftPlanningComponent implements OnInit, OnDestroy {

  public editPlanningAircraftForm: FormGroup<{
    futurePosition: FormControl<string>;
    arrivalTime: FormControl<string>;
  }>;
  public searchZoneForm: FormControl<string>;
  public loading = false;
  public locationFiltered: Observable<Zone[]>;
  public locationLoading$: Observable<boolean>;
  public trackerPlanningManager: boolean;
  public dataSource = new MatTableDataSource();
  public minDate = moment().startOf('day');

  private _allZone: Zone[] = [];
  private _subscription: Subscription = new Subscription();
  private _editedPlanning: AircraftPlanning;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: AircraftPlanning & { clashMode: boolean },
    private _aircraftPlanningEffect: AircraftPlanningEffects,
    private _dialogRef: MatDialogRef<DialogEditAircraftPlanningComponent>,
    private store: Store
  ) { }

  ngOnInit(): void {
    this._getZonesStore();
    this._initForm();
    this._initUserRights();
  }

  /**
  * Get all zones from the service
  */
  private _getZonesStore(): void {
    this.locationLoading$ = this.store.select(selectZonesLoaded$).pipe(
      map(loaded => !loaded)
    );
    this.locationFiltered = this.store.select(selectZones$).pipe(
        map(zones => zones.filter(zone => zone.site)),
        tap(zones => this._allZone = zones),
    );
  }

  /**
  * Get user rights and scope
  */
  private _initUserRights() {
    this._subscription.add(this.store.select(selectProfileUserRights$).subscribe((userRight) => {
      this.trackerPlanningManager = userRight.isPlanningManagerAct;
    }));
  }

  /**
  * Init the planning reactive form
  */
  private _initForm(): void {
    this.editPlanningAircraftForm = new FormGroup({
      futurePosition: new FormControl(this._data?.futurePosition.id, {
        validators: Validators.required,
        asyncValidators: asyncRequireMatchValidator(this.locationFiltered, 'id'),
      }),
      arrivalTime: new FormControl(moment(this._data?.arrivalTime).toISOString(), Validators.required)
    });
    this.searchZoneForm = new FormControl('');
  }

  /**
  * Submit fonction to edit an aircraft planning row
  * @param editedControls the from group containing the controls
  */
  public editPlanningAircraft(): void {
    const editedControls = this.editPlanningAircraftForm.value;
    this._editedPlanning = { ...this._data };

    const futurePosition = this._allZone.find(
        ({ id }) => editedControls.futurePosition === id
    );

    const editedActPlanning: UpdatePlanningActForm = {
      id: this._editedPlanning.id,
      arrivalTime: moment(editedControls.arrivalTime).utcOffset(0).format('YYYY-MM-DDTHH:mm:ss+0000'),
      zoneTo: editedControls.futurePosition,
    };

    if (this._data.clashMode) {
      // Update in "local" the imported line
      this.store.dispatch(updateAircraftClash(
        // Site has to be edited as it will be checked by upload process
        {
          oldPlanning: this._editedPlanning,
          editedPlanning: {
            ...this._editedPlanning,
            ...editedActPlanning,
            siteTo: futurePosition.site,
          }
        }));
      this._dialogRef.close();
    } else {
      // Update the planned line
      this._subscription.add(this._aircraftPlanningEffect.effectSubject.pipe(take(1)).subscribe((res) => {
        this.loading = false;
        if (res === 'success') {
          this._dialogRef.close();
        }
      }));
      this.loading = true;
      this.store.dispatch(updateAircraftPlanning({ payload: editedActPlanning }));
    }
  }

  /**
  * Close the mat dialog on cancel or cross click
  */
  public dialogCloseClick(): void {
    this._dialogRef.close();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
