<div role="group"
     class="range-container"
     [formGroup]="parts"
     [attr.aria-labelledby]="_formField?.getLabelId()"
     (focusin)="onFocusIn()"
     (focusout)="onFocusOut($event)">
    <input #minInput
           matInput
           class="range-element"
           formControlName="min"
           [size]="max?.toString().length"
           [placeholder]="'MINIMUM' | translate"
           [attr.aria-label]="'MINIMUM' | translate"
           (input)="_handleInput(parts.controls.min, maxInput)">
    <span class="range-spacer">&#8212;</span>
    <input #maxInput
           matInput
           class="range-element"
           formControlName="max"
           [size]="max?.toString().length"
           [placeholder]="'MAXIMUM' | translate"
           [attr.aria-label]="'MAXIMUM' | translate"
           (input)="_handleInput(parts.controls.max)"
           (keyup.backspace)="autoFocusPrev(parts.controls.max, minInput)">
</div>
